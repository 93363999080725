<script>
import BaseForm from './BaseForm.vue';
import Organization from '../../services/organization';
import Feature from '../../services/feature';
const feature = new Feature();
const org = new Organization();
import accMixin from '../../mixins/account';
import { fullName } from '../../utils';
export default {
  mixins: [accMixin],
  extends: BaseForm,
  props: {
    submitLabel: {
      type: String,
      default: 'Next'
    }
  },
  methods: {
    async submit() {
      const features = feature.defaultData('organization');

      const userFullName = fullName(this.$store.state.user.profile);
      const userEmail = this.$store.state.user.profile.email;

      this.loading = true;
      const data = {
        key: this.formData.key,
        name: this.formData.name,
        user: this.$store.state.user.uid,
        userFullName,
        userEmail,
        features
      };

      org
        .create(data)
        .then(({ id }) => {
          org.initializePermission(id);
          this.getOrganizations();
          this.$router.push(`/organizations/${id}`);
        })
        .catch(error => {
          this.error = error.message;
          setTimeout(() => {
            this.error = '';
          }, 5000);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    cancel() {
      this.$router.push('/organizations');
    }
  }
};
</script>
