<template>
  <v-main>
    <v-container>
      <v-card max-width="600" color="primary-light" class="mx-auto" outlined>
        <v-card-title>
          Set up your organization
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-subtitle> Tell us about your organization </v-card-subtitle>
        <v-card-text>
          <CreateOrganization />
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import CreateOrganization from '../../components/Organization/Create.vue';
export default {
  components: {
    CreateOrganization
  }
};
</script>

<style>
</style>
