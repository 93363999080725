import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMain,[_c(VContainer,[_c(VCard,{staticClass:"mx-auto",attrs:{"max-width":"600","color":"primary-light","outlined":""}},[_c(VCardTitle,[_vm._v(" Set up your organization "),_c(VSpacer)],1),_c(VCardSubtitle,[_vm._v(" Tell us about your organization ")]),_c(VCardText,[_c('CreateOrganization')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }